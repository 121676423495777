import Img from 'gatsby-image'
import Container from '../components/Container'
import Form from '../components/Form'

import { PageProps, graphql, useStaticQuery } from 'gatsby'
import { Check } from 'react-feather'
import { SEO } from '../SEO'
import { Site } from '../components/Site'

import { navigate } from 'gatsby'
import React, { PropsWithChildren, useContext, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'

const facts = [
  'Automated analysis of 100% of calls',
  'Sentiment interpretation',
  'Customer insights',
  'Explanation of failed calls',
  'Flagged high risk calls'
]

export default (props: PageProps) => {
  const themeContext = useContext(ThemeContext)
  const [submitted, setSubmitted] = useState(false)
  const [userName, setUserName] = useState('')
  const { copy }: any = props?.location?.state || {}

  const data = useStaticQuery(graphql`
    query Mockup {
      file(relativePath: { eq: "mockup.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const urlParams = new URLSearchParams(props.location.search);
  const successValue = urlParams.get('aws-signup');

  return (
    <Site seo={SEO.startDemo} {...props}>
      <Container>
        <StyledSignUp>
          <SignUpWrapper>
            <LeftPanel>
              <Img fluid={data.file.childImageSharp.fluid} alt='daisee user on laptop' />
              <Summary>
                <SummaryHeading>Discover the power of daisee</SummaryHeading>
                {facts.map(fact => {
                  return (
                    <Row key={fact}>
                      <i>
                        <Check size={18} color={themeContext.colors.green} />
                      </i>
                      <p>{fact}</p>
                    </Row>
                  )
                })}
              </Summary>
            </LeftPanel>
            <RightPanel>
              <FormHeading>{copy ?? 'Contact us to start a free demo'}</FormHeading>
              <MarketplaceRedirected>{successValue === 'success' && 'Thanks for signing up through AWS Marketplace. Further setup is required. Please leave your details and our team will get in touch.'}</MarketplaceRedirected>
              <Form
                setSubmitted={setSubmitted}
                setUserName={setUserName}
                formName='start-a-demo'
                successMessage='Thank you, we will be in touch within 1-2 business days. For any enquiries, feel free to contact us at info@daisee.com.'
                errorMessage='Oops, something went wrong - please try again'
                callback={() => navigate('/thankyou')}
              />
            </RightPanel>
          </SignUpWrapper>
        </StyledSignUp>
      </Container>
    </Site>
  )
}

const Thanks = ({ userName }: PropsWithChildren<{ userName: string }>) => {
  return (
    <ThanksWrapper>
      <div>
        <div>{`Thanks ${userName}, we will be in touch with you soon to set up your demo`}</div>
      </div>
    </ThanksWrapper>
  )
}

const ThanksWrapper = styled.div`
  padding: 0 1.5rem;
  font-weight: 500;
`

const MarketplaceRedirected = styled.div`
  display: flex;
  padding-bottom: 1.5rem;
  font-size: 1.25rem;
`

const Summary = styled.article`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
`

const Row = styled.article`
  display: flex;
  color: #fff;
  margin-bottom: 0.5rem;
  i {
    margin-right: 0.75rem;
  }

  p {
    font-size: 0.875rem;
    font-weight: 600;
  }
`

const SummaryHeading = styled.h2`
  margin: 0 0 1.5rem;
  font-size: 1.25rem;
  color: #fff;
`

const StyledSignUp = styled.section`
  position: relative;
  z-index: 1;
`

const SignUpWrapper = styled.div`
  display: grid;
  grid-template-columns: 38.2% 61.8%;
  width: 100%;
  height: 100.1vh;
  padding-top: 9rem;
  overflow: hidden;

  @media all and (max-width: 56.25em) {
    grid-template-columns: 1fr;
    height: auto;
  }
`

const LeftPanel = styled.div`
  background: ${({ theme }) => theme.colors.purple};
  background-size: 400% 400%;
  width: 100%;
  height: 100%;
  transition: all 0.75s ease;
`

const FormHeading = styled.h1`
  margin: 0.75rem 0 3rem;
  letter-spacing: -0.5px;
  display: flex;
  position: relative;
  text-transform: capitalize;

  &:before {
    content: '';
    position: absolute;
    top: -2rem;
    width: 4rem;
    height: 4px;
    background: ${({ theme }) => theme.colors.green};
  }

  svg {
    margin-left: 1rem;
  }
  @media all and (max-width: 36.625em) {
    font-size: 1.75rem;
    line-height: 1.5;
  }
`

const RightPanel = styled.div`
  width: 100%;
  height: 100%;
  padding: 1.5rem;
  background: #fff;
  position: relative;
  height: fit-content;

  @media all and (max-width: 56.25em) {
    grid-row: 1;
    padding-bottom: 3rem;
  }
`
